window.gjDictionary = {
  'Falta algún campo obligatorio': {
    es: 'Falta algún campo obligatorio',
    en: 'Some required field is missing',
    ca: 'Manca algun camp obligatori',
  },

  'Revisa los campos del formulario': {
    es: 'Revisa los campos del formulario',
    en: 'Review the fields of the form',
    ca: 'Revisa els camps del formulari',
  },
  'Debes aceptar la política de privacidad': {
    es: 'Debes aceptar la política de privacidad',
    en: 'You must accept the privacy policy',
    ca: `Has d'acceptar la política de privadesa`,
  },

  'Internal Server Error': {
    es: 'Error inesperado',
    en: 'Unexpected error',
    ca: 'Error inesperat',
  },
  'Te has suscrito correctamente a la newsletter': {
    es: 'Te has suscrito correctamente a la newsletter',
    en: 'You have successfully subscribed to the newsletter',
    ca: `T'has subscrit correctament a la newsletter`,
  },
  'Email already exists': {
    es: 'Este correo ya está suscrito a la newsletter',
    en: 'This email is already subscribed to the newsletter',
    ca: 'Aquest correu ja està subscrit a la newsletter',
  },
};
window.gjTranslate = function (str, lang) {
  if (gjDictionary[str] && gjDictionary[str][lang]) {
    return gjDictionary[str][lang];
  }
  return str;
};
