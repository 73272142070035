$(document).ready(function () {
  // Filter News
  window.gjFilterNews = function (formId, inputName, inputValue) {
    const filterNewsForm = $(`#${formId}`);
    let inputs = document.querySelectorAll(`#${formId} input`);
    inputs.forEach((input) => {
      if (input.name === inputName) {
        input.value = inputValue;
      }
    });
    filterNewsForm.submit();
  };

  window.gjResetNewsFilter = function (formId) {
    const filterNewsForm = $(`#${formId}`);
    let inputs = document.querySelectorAll(`#${formId} input`);
    inputs.forEach((input) => {
      input.value = '';
    });
    filterNewsForm.submit();
  };

  // Newsletter
  window.gjSuscribeToNewsletter = function (url, listId, api_key) {
    const lang = languageData.language;
    const successToast = new bootstrap.Toast(
      document.getElementById('success-toast')
    );
    const errorToast = new bootstrap.Toast(
      document.getElementById('error-toast')
    );

    const userEmail = $('#user_email').val();

    const options = {
      method: 'POST',
      headers: {
        accept: 'application/json',
        'content-type': 'application/json',
        'Api-Key': api_key,
      },
      body: JSON.stringify({
        email: userEmail,
        listIds: [parseInt(listId, 10)],
      }),
    };

    fetch(url, options)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then((response) => {
        $('#success-toast-body').html(
          gjTranslate('Te has suscrito correctamente a la newsletter', lang)
        );
        successToast.show();
      })
      .catch((err) => {
        console.error('error:', err);
        $('#error-toast-body').html(gjTranslate(err.message, lang));
        errorToast.show();
      });
  };
});
